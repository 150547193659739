import React, { useContext, useEffect, useRef, useState } from "react";
import { PreviewPanelContext } from "./PreviewPanelContext";
import { useResize } from "utilities/hookutils";
import classNames from "classnames";
import { clamp } from "utilities/numberUtils";

const calculateMaxWidth = () => {
  if (window.innerWidth <= 768) {
    return window.innerWidth;
  } else if (window.innerWidth <= 1024) {
    return window.innerWidth - 200;
  } else {
    return window.innerWidth - 400;
  }
};

const PreviewPanelRoot = () => {
  const [maxWidth, setMaxWidth] = useState(calculateMaxWidth());
  const { panel, isOpen, isOpening } = useContext(PreviewPanelContext);
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [isOverlapping, setIsOverlapping] = useState(false);

  useEffect(() => {
    if (panel && width === 0) {
      setWidth(panel.width);
    }
  }, [panel]);

  const onDragEnd = (offset) => {
    setWidth(clamp(width + offset.x, panel?.width, maxWidth));
  };

  const { offset, resizing, startDrag } = useResize(ref, onDragEnd);

  const widthWithOffset = clamp(width + offset.x, panel?.width, maxWidth);
  const rootWidth = (isOpen || isOpening) ? `${widthWithOffset}px` : 0;
  const placeholderWidth = (isOpen || isOpening) ? `calc(${widthWithOffset}px - 50vw + 960px)` : 0;

  useEffect(() => {
    const contentHolder = document.getElementById("contentholder");
    const contentHolderRight = contentHolder?.getBoundingClientRect().right || 0;
    const previewPanelLeft = window.innerWidth - widthWithOffset;
    if (!isOverlapping && contentHolderRight > previewPanelLeft) {
      setIsOverlapping(true);
    } else if (isOverlapping && contentHolderRight < previewPanelLeft) {
      setIsOverlapping(false);
    }
  }, [width, offset, isOpening, isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setMaxWidth(calculateMaxWidth());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onMouseOver = () => {
    setIsHovering(true);
  };

  const onMouseOut = () => {
    setIsHovering(false);
  };

  return <>
    <div className={classNames("preview-panel-xl-placeholder", {
      resizing
    })} style={{
      minWidth: placeholderWidth,
      maxWidth: placeholderWidth
    }} />
    <div
      style={{ minWidth: rootWidth, maxWidth: rootWidth }}
      className={classNames("preview-panel-root", {
        resizing,
        overlapping: isOverlapping
      })}>
      <div ref={ref}
        onMouseDown={startDrag}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className={classNames("preview-panel-resize", {
          overlapping: isOverlapping,
          hover: (isHovering || resizing)
        })}
      />
      <div
        onMouseDown={startDrag}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className={classNames("preview-panel-resize-handle", {
          hover: (isHovering || resizing)
        })}
      >
        <div /> <div />
      </div>
      {panel?.jsx}
    </div>
  </>;
};

export default PreviewPanelRoot;
