import classNames from "classnames";
import React from "react";
import { Loader } from "semantic-ui-react";

interface IProps {
  height?: number
  className?: string
  dontAlignCenter?: boolean
}

class Loading extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    const { height, className } = this.props;

    const wrapperClass = classNames("content flex", className || "", {
      "align-center": !this.props.dontAlignCenter
    });

    let style = {};

    if (height) {
      style = { height };
    }

    return (
      <div className={wrapperClass} style={style}>
        <Loader active inline="centered" size="small" />
      </div>
    );
  }
}

export default Loading;
