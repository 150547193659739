import { type AnyAction, type Store } from "redux";
import {
  addRequest,
  receiveDeletion,
  receiveMessage,
  receiveResponse,
  receiveUpload
} from "../redux/actionCreators/advancing";
import { onAdvancingDeletion, onAdvancingRequest, onAdvancingResponse, onAdvancingUpload, onNewAdvancingMessage } from "common/signalR";

export const connectSignalrAdvancing = (store: Store<any, AnyAction>) => {
  onNewAdvancingMessage((message) => {
    store.dispatch(receiveMessage(message));
  });

  onAdvancingRequest((request) => {
    store.dispatch(addRequest(request));
  });

  onAdvancingResponse((response) => {
    store.dispatch(receiveResponse(response));
  });

  onAdvancingUpload((upload) => {
    store.dispatch(receiveUpload(upload));
  });

  onAdvancingDeletion((clientFileId) => {
    store.dispatch(receiveDeletion(clientFileId));
  });
};
