import * as signalR from "@microsoft/signalr";
import { connectSignalrAdvancing } from "../advancing";
import {
  connectSignalrNotifications
} from "./connectSignalRNotifications";
import { type AnyAction, type Store } from "redux";

export const signalRMiddleware = () => (next: any) => (action: any) => next(action);

export const signalRStart = (store: Store<any, AnyAction>) => {
  const hubConnection = new signalR
    .HubConnectionBuilder()
    .withUrl(site.signalREndpoint)
    .configureLogging(site.signalRBrowserDebugLevel)
    .withAutomaticReconnect()
    .build();

  window.signalRConnections = {
    hub: hubConnection
  };

  connectSignalrNotifications(store);
  connectSignalrAdvancing(store);

  hubConnection.start();
};
