import { ButtonPrimary, ButtonSecondary } from "components/Atoms/Button";
import Modal from "components/modal";
import React from "react";

interface Props {
  onChoose: (value: "modern" | "legacy") => void
  onClose: () => void
  documentType: Exclude<server.dto.DocumentTypes, "unknown">

  modernLabel: string
  legacyLabel: string
}

const ModernOrLegacyModal = (props: Props) => {
  const [isSelectingModern, setIsSelectingModern] = React.useState(false);
  const [isSelectingLegacy, setIsSelectingLegacy] = React.useState(false);

  const isSelectingAnything = isSelectingModern || isSelectingLegacy;

  const labels = site.currentEnvironment[props.documentType];

  const onEsignSelected = () => {
    setIsSelectingModern(true);
    props.onChoose("modern");
  };

  const onLegacySelected = () => {
    setIsSelectingLegacy(true);
    props.onChoose("legacy");
  };
  return <Modal
    isOpen
    handleClose={props.onClose}
    title={labels.newLabel}
  >
    <div className="flex-column gap-small">
      <ButtonPrimary
        wide
        onClick={onEsignSelected}
        label={props.modernLabel}
        loading={isSelectingModern}
        iconRight="icon-rocket"
        disabled={isSelectingAnything}
      />
      <ButtonSecondary
        wide
        onClick={onLegacySelected}
        label={props.legacyLabel}
        loading={isSelectingLegacy}
        disabled={isSelectingAnything}
      />
    </div>
  </Modal>;
};

export default ModernOrLegacyModal;