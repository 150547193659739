import React from "react";
import NavigationBar from "./NavigationBar";
import { useViewport } from "utilities/hookutils";

interface TopNavigationProps {
  navigation: server.dto.Navigation
  currentPage: server.dto.RouteKeys
  newButton?: React.ReactNode
}

const useIgnorePages = (navigation: server.dto.Navigation) => {
  const viewport = useViewport();
  const lessThenDesktopSmall = viewport === "desktopSmall" || viewport === "mobile";
  const ignore = ["more", "settings", "template", "profile", "eSignLanding", "advancing", ...lessThenDesktopSmall ? [] : ["home"]];

  return ignore.includes(navigation.chosenMain);
};

const TopNavigation = (props: TopNavigationProps) => {
  const { navigation, currentPage, newButton } = props;
  const ignore = useIgnorePages(navigation);

  if (!navigation.chosenMain || ignore) {
    return null;
  }

  const selectedMenu = navigation.mainMenu.find(m => m.key === navigation.chosenMain);

  return <NavigationBar
    selectedMenu={selectedMenu}
    currentPage={currentPage}
    newButton={navigation.newButtonEnabled ? newButton : undefined}
  />;
};

export default TopNavigation;
