import React from "react";
import { Button } from "semantic-ui-react";
import BrandDropdown from "components/dropdowns/reactselect/BrandDropdown";
import Modal from "components/modal";
import BookkeepingDropdown from "components/dropdowns/reactselect/BookkeepingDropdown";

interface Props {
  documentType: Exclude<server.dto.DocumentTypes, "unknown">
  bookkeepings: server.dto.BookkeepingSmall[]
  brands: server.dto.BrandSmall[]
  onSelected: (bookkeepingId: number, brandId: number) => void
  onClose: () => void
}

const SelectBookkeepingAndBrand = (props: Props) => {
  const hasSingleBrand = props.brands.length === 1;
  const hasSingleBookkeeping = props.bookkeepings.length === 1;

  const [bookkeeping, setBookkeeping] = React.useState<server.dto.BookkeepingSmall>(hasSingleBookkeeping ? props.bookkeepings[0] : undefined);
  const [brand, setBrand] = React.useState<server.dto.BrandSmall>(hasSingleBrand ? props.brands[0] : undefined);

  const [bookkeepingEmptyError, setBookkeepingEmptyError] = React.useState(false);
  const [brandEmptyError, setBrandEmptyError] = React.useState(false);

  const [isSaving, setIsSaving] = React.useState(false);

  const onBookkeepingSelected = (bookkeeping: server.dto.BookkeepingSmall) => {
    setBookkeeping(bookkeeping);
    setBookkeepingEmptyError(false);
  };

  const onBrandSelected = (brand: server.dto.BrandSmall) => {
    setBrand(brand);
    setBrandEmptyError(false);
  };

  const onSave = () => {
    let isValid = true;
    if (!bookkeeping) {
      setBookkeepingEmptyError(true);
      isValid = false;
    }
    if (!brand) {
      setBrandEmptyError(true);
      isValid = false;
    }

    if (!isValid) return;

    setIsSaving(true);
    props.onSelected(bookkeeping.bookkeepingId, brand.brandId);
  };

  const labels = site.currentEnvironment[props.documentType];

  return <Modal
    isOpen
    handleClose={props.onClose}
    title={labels.newLabel}
  >
    <div className="flex-column gap-small">
      {!hasSingleBookkeeping && <div>
        <BookkeepingDropdown
          options={props.bookkeepings}
          onChange={onBookkeepingSelected}
          value={bookkeeping}
          config={{
            hasError: bookkeepingEmptyError
          }}
        />
      </div>}
      {!hasSingleBrand && <div>
        <BrandDropdown
          onChange={onBrandSelected}
          value={brand}
          options={props.brands}
          config={{
            hasError: brandEmptyError
          }}
        />
      </div>}
    </div>

    <div className="flex justify-end">
      <div>
        <Button
          primary
          loading={isSaving}
          disabled={isSaving}
          onClick={onSave}
        >{RESX.Buttons.btnSave}</Button>
      </div>
    </div>
  </Modal>;
};

export default SelectBookkeepingAndBrand;