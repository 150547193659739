import { UserAvatar } from "components/Atoms/Avatar";
import React from "react";
import Popup from "reactjs-popup";
import { routes } from "utilities/routing/generatedRoutes";

interface ProfileMenuProps {
  currentUser: server.dto.UserSmall
}

const ProfileMenu = (props: ProfileMenuProps) => {
  const isInternalUser = site.currentEnvironment.type === "internal";

  return <>
    <Popup
      trigger={<div className="popup-menu-avatar-icon">
        <UserAvatar size="avatar-32" user={props.currentUser} />
      </div>}
      position="bottom right"
      closeOnDocumentClick
      onOpen={() => { }}
      onClose={() => { }}
      contentStyle={{ padding: "0px", border: "none" }}
      arrow={false}
    >
      <div className="popup-menu-wrapper">
        <div className="popup-menu-profile">
          <UserAvatar size="avatar-80" user={props.currentUser} />
          <div className="popup-menu-avatar-info">
            <span className="body-text-regular">{props.currentUser.fullName}</span>
          </div>
        </div>
        <a href={routes.personal_SettingMenu().absolute} className="popup-menu-item">
          <i className="icon-avatar" />
          <span className="subtitle-text-semibold">{RESX.GeneralLabels.PersonalSettings}</span>
        </a>
        {isInternalUser && (<a href={routes.taskManager_List().absolute} className="popup-menu-item">
          <i className="icon-taskmanager" />
          <span className="subtitle-text-semibold">{RESX.GeneralLabels.TaskManager}</span>
        </a>)}
        <a
          href={window.CoreApi.Authentication.Signout.absoluteUrl("")}
          className="popup-menu-item"
        >
          <i className="icon-logout" />
          <span className="subtitle-text-semibold">{RESX.GeneralLabels.SignOut}</span>
        </a>
      </div>
    </Popup>
  </>;
};

export default ProfileMenu;
