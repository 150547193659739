 
import React from "react";
import { type Props, components } from "react-select";
import { focusOnMount } from "utilities/reactUtils";
import { type CommonReactSelectWrapperProps } from "./models";
import Sublabel from "components/Atoms/Sublabel";
import { useDebounce } from "utilities/usehooks";

// We don't care about TOption here
// If we make this generic, the typings will get a lot more complicated to support multi select dropdowns
type WrapperProps = CommonReactSelectWrapperProps<unknown, false, any>;

export interface UseSearchQuery {
  searchQuery: string
  setSearchQuery: (value: string) => void
  isDebouncing: boolean
  nonDebouncedSearchQuery: string
}
export const useSearchQuery = (initialSearchQuery = ""): UseSearchQuery => {
  const [searchQuery, setSearchQuery] = React.useState(initialSearchQuery);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  return {
    searchQuery: debouncedSearchQuery,
    setSearchQuery,
    isDebouncing: searchQuery !== debouncedSearchQuery,
    nonDebouncedSearchQuery: searchQuery
  };
};

export const useReactSelectDropdown = (
  props: WrapperProps
) => {
  const mounted = React.useRef(false);

  const noResultsFound = RESX.GeneralLabels.NoResultsFound;
  const hasCustomOptions = props.renderOption != null;

  const config = props.config ?? {};

  const reactSelectProps: Props<unknown> = {
    placeholder: config?.placeholder ?? "",
    noOptionsMessage: () => noResultsFound,

    defaultValue: null,
    getOptionValue: props.getLabel,
    getOptionLabel: (opt) => props.getValue(opt)?.toString(),

    value: props.value,
    onChange: props.onChange,
    options: [],

    formatOptionLabel: props.renderOption,

    openMenuOnFocus: true,
    menuShouldScrollIntoView: false,
    menuPlacement: "auto",

    isSearchable: config.isSearchable ?? false,
    isDisabled: config.isDisabled,
    isMulti: false,
    isLoading: config.isLoading,
    className: config.classes,
    controlShouldRenderValue: config.shouldRenderSelectedValue ?? true,
    menuPortalTarget: document.body,
    menuPosition: "absolute",

    loadingMessage: () => RESX.GeneralLabels.Searching,

    components: {
      MenuPortal: (props) => {
        // We use this className to make sure we don't close tooltips when clicking on the dropdown menu
        return <components.MenuPortal {...props} className="react-select-menu-portal" />;
      },
      DropdownIndicator: innerProps => (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...innerProps}>
            {config.isSearchable && <i className="search icon" />}
            {!config.isSearchable && <components.DownChevron size={13} />}
          </components.DropdownIndicator>
        )
      ),
      IndicatorSeparator: () => null,
      NoOptionsMessage: noOptsProps => {
        return <components.NoOptionsMessage {...noOptsProps}>
          {noResultsFound}
        </components.NoOptionsMessage>;
      },

      SingleValue: (singleValueProps) => (<components.SingleValue {...singleValueProps}>
        {props.renderSelectedValue != null && props.renderSelectedValue(singleValueProps.data)}
        {props.renderSelectedValue == null && <>
          <div>{props.getLabel(singleValueProps.data)}</div>
        </>}
      </components.SingleValue>),

      // Test the GooglePlacesDropdown if you enable this
      // Without memoizing this, the "input" will be re-rendered on every key press
      // This can cause it to lose focus after every key press
      // Input: props => (
      //     <components.Input {...props} />
      // ),

      ...(!hasCustomOptions
        ? {
          Option: innerProps => (
            <components.Option {...innerProps}>
              <div>{props.getLabel(innerProps.data)}</div>
              {props.getSubLabel && <Sublabel small>
                {props.getSubLabel(innerProps.data)}
              </Sublabel>}
            </components.Option>
          )
        }
        : {}),

      MultiValue: innerProps => (
        <components.MultiValue {...innerProps}>
          <div>{props.getLabel(innerProps.data)}</div>
        </components.MultiValue>
      )
    },

    styles: {
      menuPortal: (base) => ({
        ...base,
        borderColor: config.hasError ? "red" : undefined,
        zIndex: 9999
      }),
      control: (base, state) => ({
        ...base,
        backgroundColor: config.hasError ? "rgba(255, 0, 0, 0.1)" : base.backgroundColor,
        borderColor: config.hasError ? "red" : state.isFocused ? "black" : "rgba(0, 0, 0, 0.3)",
        boxShadow: "none",
        borderRadius: null,
        alignItems: "normal",

        minHeight: 0,
        "&:hover": {
          borderColor: config.hasError ? "red" : "black",
          boxShadow: "none"
        },
        borderBottom: config.hasError
          ? undefined
          : state.isFocused
            ? "1px solid black"
            : "1px solid rgba(0, 0, 0, 0.3)"
      }),
      valueContainer: (base) => ({
        ...base,
        alignItems: "initial",
        padding: "7px 0px 7px 7px",
        lineHeight: "1.21428571em"
      }),
      input: (base) => ({
        ...base,
        padding: 0,
        margin: 0
      }),
      placeholder: (base) => ({
        ...base,
        margin: 0
      }),
      menu: (base, state) => ({
        ...base,
        borderRadius: state.placement === "bottom" ? "0em 0em 0.30769231rem 0.30769231rem" : "0.30769231rem 0.30769231rem 0em 0em",
        marginTop: 0,
        marginBottom: 0,
        boxShadow: "none",
        border: config.hasError ? "1px solid red" : "1px solid black",
        borderTop: state.placement === "bottom" ? "none" : undefined,
        borderBottom: state.placement === "top" ? "none" : undefined,
        top: state.placement === "bottom" ? "calc(100% - 1px)" : undefined,
        bottom: state.placement === "top" ? "calc(100% - 1px)" : undefined
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: "inherit",
        opacity: 0.8,
        "&:hover": {
          color: "inherit",
          opacity: 0.8
        },
        lineHeight: 1
      }),
      singleValue: (base) => ({
        ...base,
        margin: 0,
        color: "black"
      })
    }
  };

  const ref = ref => {
    if (config.focusOnMount && !mounted.current) {
      focusOnMount(ref);
      mounted.current = true;
    }
    if (config.inputRef) config.inputRef.current = ref?.inputRef;
  };

  return {
    reactSelectProps,
    ref
  };
};