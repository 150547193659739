import React, { forwardRef } from "react";
import NotificationIcon from "./NotificationIcon";
import classNames from "classnames";

interface BigIconButtonProps {
  icon: string
  onClick: () => void
  size?: "medium" | "large"
}

const BigIconButton = forwardRef<HTMLButtonElement, BigIconButtonProps>((props, ref) =>
  <button
    ref={ref}
    className={classNames("big-icon-button", props.size || "large")}
    type="button"
    onClick={props.onClick}
  >
    <i className={classNames("big-icon-button-icon", props.size || "large", props.icon)} />
  </button>
);

interface NotificationIconButtonProps extends BigIconButtonProps {
  notificationCount: number
}

export const NotificationIconButton = forwardRef<HTMLButtonElement, NotificationIconButtonProps>((props, ref) =>
  <button
    ref={ref}
    className="big-icon-button"
    type="button"
    onClick={props.onClick}
  >
    <i className="big-icon-button-notification">
      <NotificationIcon color="#666666" />
      {props.notificationCount > 0 && (<div className={classNames("big-icon-button-notification-count notification-text", {
        "two-digit": props.notificationCount > 9 && props.notificationCount < 100,
        "three-digit": props.notificationCount > 99
      })}>
        {props.notificationCount > 99 ? "99+" : props.notificationCount}
      </div>)}
    </i>
  </button>
);

export default BigIconButton;
