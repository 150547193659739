import React from "react";
import NewAddressBook from "components/TopNavigation/NewAddressBook";
import NewPlanning from "components/TopNavigation/NewPlanning";
import NewProduction from "components/TopNavigation/NewProduction";
import NewQuote from "components/TopNavigation/NewQuote";
import NewInvoice from "components/TopNavigation/NewInvoice";
import NewPurchaseAgreement from "components/TopNavigation/NewPurchaseAgreement";
import NewContract from "components/TopNavigation/NewContract";
import { createRoot } from "react-dom/client";
import ReduxDefaultProviders from "./ReduxDefaultProviders";
import TopBar from "components/TopNavigation/TopBar";
import TopNavigation from "components/TopNavigation/TopNavigation";
import { type Store } from "redux";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import SidebarNavigation from "components/SidebarNavigation/SidebarNavigation";
import RightSideNavigation from "components/RightSideNavigation/RightSideNavigation";

declare const viewModel: server.viewmodel.IFrontModel;

export const renderBreadcrumbsIfDefined = () => {
  // This check makes sure that if the viewModel is not defined, we don't get an error.
  if (!(window as any).viewModel) {
    return null;
  }

  if (viewModel.breadcrumbs && viewModel.breadcrumbs.length > 0) {
    return <Breadcrumbs breadcrumbs={viewModel.breadcrumbs} />;
  }

  return null;
};

export const getNewButton = () => {
  const showNewPlanningButton = site.permissions.show.canCreate ||
    site.permissions.flight.canCreate ||
    site.permissions.train.canCreate ||
    site.permissions.groundTransport.canCreate ||
    site.permissions.externalCalendar.canCreate;

  let newButton = null;
  if (site.navigation.chosenMain === "addressBook" && site.permissions.addressBook.canCreate) {
    newButton = <NewAddressBook />;
  } else if (site.navigation.chosenMain === "production" && site.permissions.production.canCreate) {
    newButton = <NewProduction />;
  } else if (site.navigation.chosenMain === "planning" && showNewPlanningButton) {
    newButton = <NewPlanning />;
  } else if (site.navigation.chosenMain === "quote" && site.permissions.quote.canCreate) {
    newButton = <NewQuote />;
  } else if (site.navigation.chosenMain === "invoice" && site.permissions.invoice.canCreate) {
    newButton = <NewInvoice />;
  } else if (site.navigation.chosenMain === "purchaseAgreement" && site.permissions.purchaseAgreement.canCreate) {
    newButton = <NewPurchaseAgreement />;
  } else if (site.navigation.chosenMain === "contract" && site.permissions.contract.canCreate) {
    newButton = <NewContract />;
  }

  return newButton;
};

const bootstrapNavigation = (store: Store) => {
  const sidebarNavigationHolder = document.getElementById("sidebar-navigation");

  if (sidebarNavigationHolder && site.currentEnvironment != null) {
    const root = createRoot(sidebarNavigationHolder);
    root.render(
      <SidebarNavigation
        navigation={site.navigation}
        currentPage={site.currentPage}
      />
    );
  }

  const topNavigationHolder = document.getElementById("content-header");

  if (topNavigationHolder && site.currentEnvironment != null) {
    const root = createRoot(topNavigationHolder);
    root.render(
      <ReduxDefaultProviders store={store}>
        <TopBar
          currentUser={site.currentUser}
          environmentType={site.currentEnvironment.type}
          notificationCount={site.notificationCount}
          onMenuOpen={() => { window.onSideNavOpen(); }}
        />
        {renderBreadcrumbsIfDefined()}
        <TopNavigation
          currentPage={site.currentPage}
          navigation={site.navigation}
          newButton={getNewButton()}
        />
      </ReduxDefaultProviders>
    );
  }

  const rightSideNavigationHolder = document.getElementById("right-side-navigation");

  if (rightSideNavigationHolder && site.currentEnvironment != null) {
    const root = createRoot(rightSideNavigationHolder);
    root.render(
      <RightSideNavigation
        title={site.navigation.rightSideNavigation.title}
        menuItems={site.navigation.rightSideNavigation.items}
        currentPage={site.currentPage}
      />
    );
  }
};

export default bootstrapNavigation;
