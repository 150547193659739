import classNames from "classnames";
import React from "react";

export type AvatarSizes = "avatar" | "avatar-120" | "avatar-30" | "avatar-32" | "avatar-45" | "avatar-80" | "avatar-25";

interface AvatarProps {
  avatarUrl?: string
  placeholderUrl: string
  name: string
  size?: AvatarSizes
  inactive?: boolean
}

const Avatar = ({ avatarUrl, name, size = "avatar", inactive, placeholderUrl }: AvatarProps) => {
  const url = avatarUrl ?? placeholderUrl;

  const classes = classNames(size, {
    desaturate: inactive
  });

  return (<div style={{ lineHeight: 1 }}>
    <img style={{ objectFit: "contain" }} src={url} className={classes} title={name} />
  </div>);
};

interface UserAvatarProps {
  user: server.dto.UserSmall
  size?: AvatarSizes
  inactive?: boolean
}

export const UserAvatar = ({ user, size = "avatar", inactive }: UserAvatarProps) =>
  <Avatar
    avatarUrl={user.avatarUrl}
    placeholderUrl={window.serverReference.constants.user.noUserAvatarUploaded}
    name={user.fullName}
    size={size}
    inactive={inactive}
  />;

interface ContactAvatarProps {
  profileImageUrl?: string
  gender: server.dto.Genders
  name: string
  size?: AvatarSizes
};

const getPlaceholderUrl = (gender: server.dto.Genders) => {
  if (gender === "male") return `${site.legacyPageBaseUrl}/images/gender_male.png`;
  if (gender === "female") return `${site.legacyPageBaseUrl}/images/gender_female.png`;

  return `${site.legacyPageBaseUrl}/images/gender_unspecified.png`;
};

export const ContactAvatar = ({ profileImageUrl, gender, name, size = "avatar" }: ContactAvatarProps) =>
  <Avatar
    avatarUrl={profileImageUrl}
    placeholderUrl={getPlaceholderUrl(gender)}
    name={name}
    size={size}
  />;

export default Avatar;