import { type Dispatch } from "redux";
import { createAction } from "redux-actions";

import { displayError } from "./errorHandling";
import { Actions } from "../actions/actions";

// action creators
const addRequest = createAction<any, any>(
  Actions.Advancing.REQUEST_ADD,
  r => r
);

const replaceRequest = createAction<server.dto.AdvancingRequest, server.dto.AdvancingRequest>(
  Actions.Advancing.REQUEST_REPLACE,
  r => r
);

const receiveMessage = createAction<server.dto.AdvancingMessage, server.dto.AdvancingMessage>(
  Actions.Advancing.MESSAGE_RECEIVE,
  message => message
);

const receiveResponse = createAction<server.dto.AdvancingResponse, server.dto.AdvancingResponse>(
  Actions.Advancing.RESPONSE_RECEIVE,
  r => r
);

const receiveRequests = createAction<server.dto.AdvancingRequest[], server.dto.AdvancingRequest[]>(
  Actions.Advancing.REQUESTS_RECEIVE,
  requests => requests
);

const receiveUpload = createAction<server.dto.AdvancingUpload, server.dto.AdvancingUpload>(
  Actions.Advancing.UPLOAD_RECEIVE,
  upload => upload
);

const receiveDeletion = createAction<number, number>(
  Actions.Advancing.DELETION_RECEIVE,
  clientFileId => clientFileId
);

const handleAdvancingError = (restError: any): any => (dispatch: Dispatch) => {
  if (restError?.response?.data?.errors) {
    if (restError.response.data.errors.length === 1) {
      const error = restError.response.data.errors[0];

      if (error.target === "Advancing") {
        dispatch(displayError(restError, error.message));
        return;
      }
    }
  }

  dispatch(displayError(restError));
};

export {
  addRequest,
  handleAdvancingError,
  receiveMessage,
  receiveRequests,
  receiveResponse,
  receiveUpload,
  receiveDeletion,
  replaceRequest
};
