import React from "react";
import LoginPanelContent from "./routes/LoginPanelContent";
import AuthPanel from "./routes/components/AuthPanel";
import { homepage, needHelp } from "./routes/components/links";

interface Props {
  viewModel: server.viewmodel.AccountSignInFrontModel
}

const LoginApp = (props: Props) => {
  const [error, setError] = React.useState<string>(props.viewModel.error);
  const [errorDescription, setErrorDescription] = React.useState<string>(props.viewModel.errorDescription);
  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);

  const onLoginClick = async (ev: React.FormEvent<HTMLFormElement>, username: string, password: string) => {
    ev.preventDefault();

    setIsLoggingIn(true);

    try {
      const { data: loginOutcome }: { data: server.dto.Outcome } = await window.CoreApi.Authentication.SignInWithCredentials({
        username,
        password
      });

      if (loginOutcome.hasErrors) {
        setError(loginOutcome.errors.join("\n"));
        setErrorDescription("");
        setIsLoggingIn(false);
      } else {
        window.parent?.postMessage?.("loggedIn", "*"); // Any external System One UIs can listen for this event to know when the user has logged in
        window.location.href = props.viewModel.returnUrl;
      }
    } catch {
      setError(RESX.GeneralLabels.SomethingUnexpectedHappened);
      setErrorDescription("");
      setIsLoggingIn(false);
    }
  };

  return <AuthPanel additionalFooterContent={<>{homepage}|{needHelp}</>}>
    <LoginPanelContent
      onLogin={onLoginClick}
      returnUrl={props.viewModel.returnUrl}
      errorMessage={error}
      errorDescription={errorDescription}
      isLoggingIn={isLoggingIn}
      browser={null}
    />
  </AuthPanel>;
};

export default LoginApp;