import { type AnyAction, type Store } from "redux";
import {
  actions
} from "./index";
import { onAddNotifications, onNotificationsHidden, onNotificationsRead, onNotificationsUnread, onSerializationCheck, onSetNotificationBadge } from "common/signalR";

export const connectSignalrNotifications = (store: Store<any, AnyAction>) => {
  onAddNotifications(notifications => {
    store.dispatch(actions.updateNotificationBadge(notifications.badge));
    store.dispatch(actions.receiveNotifications(notifications.notifications));
  });

  onSerializationCheck((data) => {
    console.log("serializationcheck", data);
  });

  onSetNotificationBadge((badge) => {
    store.dispatch(actions.updateNotificationBadge(badge));
  });

  onNotificationsRead((notificationIds) => {
    store.dispatch(actions.notificationsRead(notificationIds));
  });

  onNotificationsUnread((notificationIds) => {
    store.dispatch(actions.notificationsUnread(notificationIds));
  });

  onNotificationsHidden((notificationIds) => {
    store.dispatch(actions.notificationsHidden(notificationIds));
  });
};
