import React from "react";
import { type Store } from "redux";
import DefaultProviders from "./DefaultProviders";
import { Provider } from "react-redux";

interface Props {
  store: Store<any>
}

const ReduxDefaultProviders = (props: React.PropsWithChildren<Props>) => {
  return <Provider store={props.store}>
    <DefaultProviders>
      {props.children}
    </DefaultProviders>
  </Provider>;
};

export default ReduxDefaultProviders;