import React from "react";
declare const window: any;

interface IProps {
  returnUrl: string
  isLoggingIn: boolean
}

interface LoginWithProps {
  onClick?: (ev: React.MouseEvent<HTMLAnchorElement>) => void
  url?: string
}

const LoginWithGoogle = (props: LoginWithProps) => {
  return <a href={props.url} onClick={props.onClick} className="social-login-button google">
    <img src={`${site.legacyPageBaseUrl}/images/google_logo.svg`} />
    <span className="social-login-google-text">Continue with Google</span>
  </a>;
};

const LoginWithApple = (props: LoginWithProps) => {
  return <a href={props.url} onClick={props.onClick} className="social-login-button apple">
    <img src={`${site.legacyPageBaseUrl}/images/apple_logo.svg`} />
    <span className="social-login-apple-text">Continue with Apple</span>
  </a>;
};

const SocialLoginButtonsRegular = (props: IProps) => {
  const { returnUrl } = props;

  React.useEffect(() => {
    if (window.ReactNativeWebView) {
      throw new Error("This is the wrong component to use in a ReactNativeWebView.");
    }
  }, []);

  const signInUrl = (provider: "google") => {
    if (props.isLoggingIn) return;

    const signInApi = `${site.coreApiBaseUrl}/${provider}/signin`;

    return (returnUrl)
      ? `${signInApi}?returnurl=${encodeURIComponent(returnUrl)}`
      : signInApi;
  };

  return <LoginWithGoogle url={signInUrl("google")} />;
};

const SocialLoginButtonsReactNative = () => {
  const [appleSigninVisible, setAppleSigninVisible] = React.useState(false);
  const displayAppleSignin = (e) => {
    if (e.data === "displayAppleSignin") {
      setAppleSigninVisible(true);
    }
  };

  React.useEffect(() => {
    if (!window.ReactNativeWebView) {
      throw new Error("Expect this component to be used in ReactNativeWebView.");
    }

    window.ReactNativeWebView.postMessage("shouldDisplayAppleSignin");
  }, []);

  React.useEffect(() => {
    window.addEventListener("message", displayAppleSignin);

    return () => {
      window.removeEventListener("message", displayAppleSignin);
    };
  }, [displayAppleSignin]);

  const onGoogleLoginClick = (ev) => {
    ev.preventDefault();
    window.ReactNativeWebView.postMessage("googleLoginClicked");
  };

  const onAppleSigninClick = (ev) => {
    ev.preventDefault();
    window.ReactNativeWebView.postMessage("appleSigninClicked");
  };

  return (
    <>
      <LoginWithGoogle onClick={onGoogleLoginClick} />
      {appleSigninVisible && <LoginWithApple onClick={onAppleSigninClick} />}
    </>
  );
};

const SocialLoginButtons = (props: IProps) => {
  return (window.ReactNativeWebView)
    ? (<SocialLoginButtonsReactNative />)
    : (<SocialLoginButtonsRegular {...props} />);
};

export { LoginWithGoogle, LoginWithApple, SocialLoginButtons };