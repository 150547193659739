import { ButtonPrimary } from "components/Atoms/Button";
import Modal from "components/modal";
import React from "react";
import { routes } from "utilities/routing/generatedRoutes";

interface Props {
  onClose: () => void
  isOpen: boolean
}

const NewAddressBookModal = (props: Props) => {
  return <Modal
    isOpen={props.isOpen}
    handleClose={props.onClose}
    displayHeader={false}
    gap="small"
  >
    {site.currentEnvironment.functionGroups.artist.enabled && (<div>
      <ButtonPrimary
        style={{ justifyContent: "flex-start" }}
        label={site.currentEnvironment.functionGroups.artist.newLabel}
        href={`${routes.company_New().absolute}?artist=true`}
      />
    </div>)}
    {site.currentEnvironment.functionGroups.venue.enabled && (<div>
      <ButtonPrimary
        style={{ justifyContent: "flex-start" }}
        label={site.currentEnvironment.functionGroups.venue.newLabel}
        href={`${routes.company_New().absolute}?venue=true`}
      />
    </div>)}
    {site.currentEnvironment.functionGroups.promoter.enabled && (<div>
      <ButtonPrimary
        style={{ justifyContent: "flex-start" }}
        label={site.currentEnvironment.functionGroups.promoter.newLabel}
        href={`${routes.company_New().absolute}?promoter=true`}
      />
    </div>)}
    {site.currentEnvironment.company.enabled && (<div>
      <ButtonPrimary
        style={{ justifyContent: "flex-start" }}
        label={site.currentEnvironment.company.newLabel}
        href={routes.company_New().absolute}
      />
    </div>)}
    {site.currentEnvironment.contact.enabled && (<div>
      <ButtonPrimary
        style={{ justifyContent: "flex-start" }}
        label={site.currentEnvironment.contact.newLabel}
        href={routes.contact_New().absolute}
      />
    </div>)}
  </Modal>;
};

export default NewAddressBookModal;