import React, { createContext, useCallback } from "react";

interface Panel {
  jsx: JSX.Element
  id: string
  width: number
};

interface PreviewPanelContextValue {
  panel: Panel | null
  isClosing: boolean
  isOpening: boolean
  isOpen: boolean
  openPanel: (panel: Panel) => void
  updatePanel: (jsx: JSX.Element) => void
  closePanel: () => void
}

const defaultPreviewPanelContext = {
  panel: null,
  isClosing: false,
  isOpening: false,
  isOpen: false,
  openPanel: () => { },
  updatePanel: () => { },
  closePanel: () => { }
};

export const PreviewPanelContext = createContext<PreviewPanelContextValue>(defaultPreviewPanelContext);

export const PreviewPanelProvider = (props: React.PropsWithChildren<unknown>) => {
  const [panel, setPanel] = React.useState<Panel | null>(null);
  const [isOpening, setIsOpening] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const { children } = props;

  const openPanel = useCallback((p: Panel) => {
    if (isOpening || isClosing) {
      return;
    }

    setPanel(p);
    setIsOpening(true);
    setTimeout(() => {
      setIsOpening(false);
    }, 300);
  }, [setIsOpening, isClosing]);

  const updatePanel = useCallback((jsx: JSX.Element) => {
    if (panel) {
      setPanel({ ...panel, jsx });
    }
  }, [panel]);

  const closePanel = useCallback(() => {
    if (isOpening || isClosing) {
      return;
    }

    if (panel) {
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
        setPanel(null);
      }, 300);
    }
  }, [panel, isOpening, isClosing]);

  return (
    <PreviewPanelContext.Provider value={{
      panel,
      isClosing,
      isOpening,
      isOpen: !(isOpening || isClosing) && panel !== null,
      openPanel,
      updatePanel,
      closePanel
    }}>
      {children}
    </PreviewPanelContext.Provider>
  );
};