import * as moment from "moment";
import React from "react";
import { createRoot } from "react-dom/client";

import { type Store } from "redux";
import { bootstrapSystemOneWithoutRedux } from "./common/bootstrapSystemOne";

import LoginApp from "authentication/LoginApp";
import SystemOneApp from "common/SystemOneApp";

declare const viewModel: server.viewmodel.AccountSignInFrontModel;

jQuery(() => {
  moment.locale(site.culture.uiLocale);

  const pageComponents = (store: Store<any>) => {
    const root = createRoot(document.getElementById("app"));
    root.render(
      <SystemOneApp store={store}>
        <LoginApp
          viewModel={viewModel}
        />
      </SystemOneApp>);
  };

  bootstrapSystemOneWithoutRedux(pageComponents);
});
