import React from "react";

export const joinComponents = (components: JSX.Element[], separator) => {
  const result = [];

  components.forEach((component, index) => {
    result.push(component);

    if (index < components.length - 1) {
      result.push(separator);
    }
  });

  return <React.Fragment>{result}</React.Fragment>;
};

export const replaceNewLineWithBr = (text: string) => {
  const lines = text.split("\n");

  return joinComponents(lines.map((line, index) => {
    return <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>;
  }), "");
};

export const focusOnMount = (ref: { focus: () => void }) => {
  if (ref) {
    // When we focus on things inside a tooltip, the tooltip initially gets rendered on top of the page before it correctly repositions itself
    // So we need to wait a bit before focusing
    setTimeout(() => {
      ref?.focus();
    }, 10);
  }
};

export const blurAndSubmitOnEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
  if (e.key === "Enter") {
    e.currentTarget.blur();
    if (e.currentTarget.form) {
      e.currentTarget.form.requestSubmit();
    }
  }
};