import { type ViewportType, viewports } from "./hookutils";

export const isViewport = (src: ViewportType) => {
  return {
    smallerThan: (dst: ViewportType) => {
      return viewports.indexOf(src) < viewports.indexOf(dst);
    },
    biggerThan: (dst: ViewportType) => {
      return viewports.indexOf(src) > viewports.indexOf(dst);
    }
  };
};