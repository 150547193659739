import React from "react";
import ModernOrLegacyForDocumentList from "./app";

interface Props {
  documentType: Exclude<server.dto.DocumentTypes, "unknown">
  bookkeepings: server.dto.BookkeepingSmall[]
  brands: server.dto.BrandSmall[]
}

const AppForEsign = (props: Props) => {
  let esignEnabled: "legacy" | "modern" | "legacyAndModern";
  switch (site.currentEnvironment.eSignEnabled) {
    case "onlyLegacy": esignEnabled = "legacy"; break;
    case "onlyESign": esignEnabled = "modern"; break;
    case "legacyAndESign": esignEnabled = "legacyAndModern"; break;
  }

  return <ModernOrLegacyForDocumentList
    documentType={props.documentType}
    bookkeepings={props.bookkeepings}
    brands={props.brands}
    modernLabel={RESX.ESign.eSign}
    legacyLabel={RESX.ESign.ClassicWorkflow}
    enabled={esignEnabled}

    create={async (bookkeepingId, brandId, documentType) => window.CoreApi.ESign.Create({
      bookkeepingId,
      brandId,
      documentType
    })}
  />;
};

export default AppForEsign;