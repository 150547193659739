import React from "react";
import { Loader } from "semantic-ui-react";
import { Modal } from "../../components";

const App = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [environments] = React.useState<server.dto.AvailableEnvironments | undefined>(undefined);
  const [firstTimeOpening] = React.useState<boolean>(true);

  const closeModal = () => { setIsOpen(false); };

  // React.useEffect(() => {
  //    const topEnvironmentIcon = document.getElementById("top-environment");

  //    if (topEnvironmentIcon) {
  //        topEnvironmentIcon.addEventListener("click", openModal);
  //    }

  //    // On component unmount
  //    return () =>  {
  //        if (topEnvironmentIcon) {
  //            topEnvironmentIcon.removeEventListener("click", openModal);
  //        }
  //    };
  // }, []);

  React.useEffect(() => {
    if (isOpen && firstTimeOpening) {
      // TODO: Get environments when the endpoint is reimplemented
      throw new Error("Not implemented");
      // axios.get("/api/v1/authentication/environments")
      // window.Api.Authentication.
      //     .then(res => setEnvironments(res.data));

      // setFirstTimeOpening(false);
    }
  }, [isOpen]);

  return <Modal isOpen={isOpen} width="small" handleClose={closeModal}>
    {/* {(environments != null) && <Environments loggedinUser={site.currentUser} environments={environments} />} */}
    {(environments == null) && <Loader />}
  </Modal>;
};

export default App;