import React from "react";

interface Props {
  onClick: () => void;
}

const ClearableButton = (props: Props) => {
  return <div
    className="flex padding align-center justify-center hoverable grey-100 border-top border-right border-bottom"
    style={{ width: 31, lineHeight: "1em" }}
    onClick={props.onClick}
  >
    <div>
      <i className="icon-x small" />
    </div>
  </div>
}

export default ClearableButton;