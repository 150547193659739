import React from "react";
import { Button, type Input, Popup } from "semantic-ui-react";
import { type IPagination } from "../model";
import classNames from "classnames";
import NumberInput from "components/NumberInput";
import { clamp } from "utilities/numberUtils";
import { getPageNumbers } from "utilities/paginationUtils";
import { useViewport } from "utilities/hookutils";

interface Props {
  onPaginate: (page: number) => void
  paginationData: IPagination
  noInitialPagination?: boolean
}

const Pagination = (props: Props) => {
  const [pageQuery, setPageQuery] = React.useState(undefined);
  const [isPageSearchOpen, setIsPageSearchOpen] = React.useState(false);

  const viewport = useViewport();

  // pages will be like [1, 2, 3, 4, 5, 6, 7], ie 3 before and 3 after the center point
  let pagesBeforeAndAfterCenterPoint = 3;
  switch (viewport) {
    case "mobile":
      pagesBeforeAndAfterCenterPoint = 1;
      break;
    case "desktopSmall":
      pagesBeforeAndAfterCenterPoint = 2;
      break;
    default:
      pagesBeforeAndAfterCenterPoint = 3;
      break;
  }

  const pagesDisplayed = (pagesBeforeAndAfterCenterPoint * 2) + 1;

  const pageNumbers = getPageNumbers(props.paginationData.page, props.paginationData.pageCount, pagesBeforeAndAfterCenterPoint);

  const inputRef = React.useRef<Input>();
  const pageSearchRef = React.useRef<HTMLDivElement>();

  const onGoToPage = (page: number) => {
    // Ensure page is within bounds
    const newPage = clamp(page, 1, props.paginationData.pageCount);

    props.onPaginate(newPage);
    setIsPageSearchOpen(false);
  };

  const openPageSearch = () => {
    setIsPageSearchOpen(true);
  };

  const closePageSearch = () => {
    setIsPageSearchOpen(false);
  };

  React.useEffect(() => {
    if (!props.noInitialPagination) {
      props.onPaginate(1);
    }
  }, []);

  React.useEffect(() => {
    if (isPageSearchOpen) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, [isPageSearchOpen]);

  if (props.paginationData.pageCount <= 1) {
    return <></>;
  }

  return <div className="pagination_small">
    {pageNumbers.map((n) => {
      const numberClasses = classNames({
        current: props.paginationData.page === n,
        "grey-100": props.paginationData.page !== n
      });

      return <div
        key={n}
        onClick={() => { props.onPaginate(n); }}
        className={numberClasses}>
        {n}
      </div>;
    })}

    {props.paginationData.pageCount > pagesDisplayed &&
      <>
        <div
          ref={pageSearchRef}
          onClick={openPageSearch}
          className="grey-100 margin-left">
          <i className="icon-magnifier mini"></i>
        </div>
        <Popup
          context={pageSearchRef}
          onClose={closePageSearch}
          open={isPageSearchOpen}
          position="top center"
          basic={true}
        >
          <div className="padding-large flex gap">
            <div>
              <NumberInput
                innerRef={inputRef}
                width={110}
                value={pageQuery}
                onEnterPressed={(v) => { onGoToPage(v.value); }}
                onChanged={(v) => { setPageQuery(v.value); }}
                placeholder={`${RESX.GeneralLabels.Page} 1 - ${props.paginationData.pageCount}`}
                decimalPoints={0}
                canBeNegative={false}
                canBeEmpty
              />
            </div>
            <div>
              <Button primary color="blue" type="button" onClick={() => { onGoToPage(pageQuery); }}>
                GO
              </Button>
            </div>
          </div>
        </Popup>
      </>
    }
  </div >;
};

export default Pagination;