declare global {
  interface Window {
    coordinator: {
      newappointment: {
        startChoose: (config: Partial<Config>) => void
        setArtist: (artist: any) => void
      }
    }
  }
}

export interface Config {
  fromItem: server.dto.FromItem
  date: string
  artist: server.dto.ArtistSmall
};

export const newAppointment = (config?: Partial<Config>) => {
  window.coordinator.newappointment.startChoose(config);
};

export const setArtist = (artist: server.dto.ArtistSmall) => {
  window.coordinator.newappointment.setArtist(artist);
};