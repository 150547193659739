import React from "react";

interface NotificationIconProps {
  color: string
}

const NotificationIcon = ({ color }: NotificationIconProps) =>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1416_27537)">
      <path d="M23.7589 20.6338L22.3572 19.2322C21.8884 18.7633 21.625 18.1275 21.625 17.4644V13.0676C21.625 10.3677 19.7863 7.9827 17.2353 7.3948C17.241 7.34672 17.25 7.29957 17.25 7.25C17.25 6.56059 16.6894 6 16 6C15.3106 6 14.75 6.56062 14.75 7.25C14.75 7.29871 14.7589 7.345 14.7644 7.3923C12.2531 7.95617 10.3749 10.1936 10.3749 12.8749V17.4644C10.3749 18.1275 10.1115 18.7633 9.64271 19.2322L8.24107 20.6338C8.00662 20.8682 7.87494 21.1862 7.87494 21.5177V21.5187C7.87494 22.2677 8.48217 22.875 9.23119 22.875H13.5V23.5C13.5 24.8807 14.6193 26 16 26C17.3807 26 18.5 24.8806 18.5 23.5V22.875H22.7688C23.5178 22.875 24.125 22.2677 24.125 21.5187V21.5177C24.125 21.1862 23.9933 20.8682 23.7589 20.6338Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_1416_27537">
        <rect width="20" height="20" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>;

export default NotificationIcon;