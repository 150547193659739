import axios from "axios";
import { normalize } from "normalizr";

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = async (endpoint) =>

  axios
    .get(endpoint, { withCredentials: true })
    .then((r) => r.data)

    //    .then(response =>
    //      response.json().then(json => {
    //        if (!response.ok) {
    //
    //          return Promise.reject(json)
    //        }
    //
    //        const camelizedJson = camelizeKeys(json)
    //        const nextPageUrl = getNextPageUrl(response)
    //
    //        return Object.assign({},
    //          normalize(camelizedJson, schema),
    //          { nextPageUrl }
    //        )
    //      })
    //    )
    ;
// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "Call API";

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default () => (next) => (action) => {
  const callApiAction = action[CALL_API];
  if (typeof callApiAction === "undefined") {
    return next(action);
  }

  const { endpoint } = callApiAction;
  const { schema, types } = callApiAction;

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType, paginationType] = types;
  next(actionWith({ type: requestType }));

  if (typeof endpoint === "function") {
    // endpoint = endpoint(store.getState());
    return endpoint().then(
      (data) => {
        next(actionWith({
          payload: data.data,
          type: successType
        }));
      }).catch((error) => {
      next(actionWith({
        error: error.message || RESX.GeneralLabels.SomethingUnexpectedHappened,
        type: failureType
      }));
    });
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }

  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  return callApi(endpoint).then(
    (data) => {
      if (paginationType && data.pagination) {
        next(actionWith({
          payload: data.pagination,
          type: paginationType
        }));
      }

      let payload = data;

      if (schema) {
        if (data.data) {
          payload = normalize(data.data, schema);
        } else {
          payload = normalize(data, schema);
        }
      }

      next(actionWith({
        payload,
        type: successType
      }));
    }).catch((error) => {
    next(actionWith({
      error: error.message || RESX.GeneralLabels.SomethingUnexpectedHappened,
      type: failureType
    }));
  });
};
