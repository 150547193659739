export const Actions = {
  Lodging: {
    LODGINGS_SEARCH_REQUEST: "LODGINGS_SEARCH_REQUEST",
    LODGINGS_SEARCH_RECEIVE: "LODGINGS_SEARCH_RECEIVE",
    LODGINGS_SEARCH_FINISH: "LODGINGS_SEARCH_FINISH",
    LODGING_RECEIVE: "LODGING_RECEIVE",
    GOOGLE_PLACE_DETAILS_RECEIVE: "GOOGLE_PLACE_DETAILS_RECEIVE",
    GOOGLE_PLACE_DETAILS_RESET: "GOOGLE_PLACE_DETAILS_RESET",
    GOOGLE_PLACES_SEARCH_REQUEST: "GOOGLE_PLACES_SEARCH_REQUEST",
    GOOGLE_PLACES_SEARCH_RECEIVE: "GOOGLE_PLACES_SEARCH_RECEIVE",
    GOOGLE_PLACES_SEARCH_ERROR: "GOOGLE_PLACES_SEARCH_ERROR",
    SET_LODGING_PAGE: "SET_LODGING_PAGE",
    SET_LODGING_FILTER: "SET_LODGING_FILTER"
  },
  Reservation: {
    RESERVATION_ARTIST_CONTACTS_RECEIVE: "RESERVATION_ARTIST_CONTACTS_RECEIVE",
    RESERVATION_LIST: "RESERVATION_LIST",
    RESERVATION_UPDATE: "RESERVATION_UPDATE",
    RESERVATION_ROOM_UPDATE: "RESERVATION_ROOM_UPDATE",

    RESERVATION_GET_REQUEST: "RESERVATION_GET_REQUEST",
    RESERVATION_GET_SUCCESS: "RESERVATION_GET_SUCCESS",
    RESERVATION_GET_FAIL: "RESERVATION_GET_FAIL",

    RESERVATION_UPDATE_REQUEST: "RESERVATION_UPDATE_REQUEST",
    RESERVATION_UPDATE_SUCCESS: "RESERVATION_UPDATE_SUCCESS",
    RESERVATION_UPDATE_FAIL: "RESERVATION_UPDATE_FAIL",

    RESERVATION_ROOM_UPDATE_REQUEST: "RESERVATION_ROOM_UPDATE_REQUEST",
    RESERVATION_ROOM_UPDATE_SUCCESS: "RESERVATION_ROOM_UPDATE_SUCCESS",
    RESERVATION_ROOM_UPDATE_FAIL: "RESERVATION_ROOM_UPDATE_FAIL",

    RESERVATION_SET_STEP: "RESERVATION_SET_STEP",

    RESET_ROOM_TO_DEFAULT_STATE: "RESET_ROOM_TO_DEFAULT_STATE",
    SET_CURRENT_ROOM_BY_ID: "SET_CURRENT_ROOM_BY_ID",
    SET_NEXT_BUTTON_IS_LOADING: "SET_NEXT_BUTTON_IS_LOADING",
    COPY_ROOM_FROM_LAST: "COPY_ROOM_FROM_LAST",
    CANCEL_ROOM_EDIT: "CANCEL_ROOM_EDIT",
    SET_ROOM_ADD_BUTTON_IS_LOADING: "SET_ROOM_ADD_BUTTON_IS_LOADING",
    REFRESH_EDITED_ROOM: "REFRESH_EDITED_ROOM",
    SET_SAVE_ROOM_BUTTON_IS_LOADING: "SET_SAVE_ROOM_BUTTON_IS_LOADING",
    SET_ACCOMMODATION_MODAL_VISIBILITY: "SET_ACCOMMODATION_MODAL_VISIBILITY",
    ACCOMMODATION_MODAL_CLOSE: "ACCOMMODATION_MODAL_CLOSE"
  },
  Advancing: {
    REQUEST_ADD: "REQUEST_ADD",
    REQUEST_REPLACE: "REQUEST_REPLACE",
    MESSAGE_RECEIVE: "MESSAGE_RECIEVE",
    REQUESTS_RECEIVE: "REQUESTS_RECEIVE",
    RESPONSE_RECEIVE: "RESPONSE_RECEIVE",
    UPLOAD_RECEIVE: "UPLOAD_RECEIVE",
    DELETION_RECEIVE: "DELETION_RECEIVE",
    INVITATION_RECEIVE: "INVITATION_RECEIVE",

    ADVANCINGREQUESTS_REQUEST: "ADVANCINGREQUESTS_REQUEST",
    ADVANCINGREQUESTS_SUCCESS: "ADVANCINGREQUESTS_SUCCESS",
    ADVANCINGREQUESTS_FAILURE: "ADVANCINGREQUESTS_FAILURE",
    ADVANCINGREQUESTS_PAGINATION: "ADVANCINGREQUESTS_PAGINATION"
  },
  Availability: {
    APPOINTMENT_TYPES_FILTER: "APPOINTMENT_TYPES_FILTER",
    APPOINTMENT_PHASE_FILTER: "APPOINTMENT_PHASE_FILTER",
    ARTISTS_RECEIVE: "ARTISTS_RECEIVE",
    AVAILABLE_RECEIVE: "AVAILABLE_RECEIVE",
    AVAILABLE_CLEAR: "AVAILABLE_CLEAR",
    AVAILABLE_USER_DATEPICKER_DATE_TOGGLE: "AVAILABLE_USER_DATEPICKER_DATE_TOGGLE",
    AVAILABLE_ARTIST_SELECT_TOGGLE: "AVAILABLE_ARTIST_SELECT_TOGGLE",
    AVAILABLE_DATE_ON: "AVAILABLE_DATE_ON",
    AVAILABLE_DATE_OFF: "AVAILABLE_DATE_OFF",
    HIDE_DAYS_WITH_OPTIONS_SET: "HIDE_DAYS_WITH_OPTIONS_SET",
    HIDE_DAYS_WITH_EXTERNALEVENTS_SET: "HIDE_DAYS_WITH_EXTERNALEVENTS_SET",
    CONTACTS_RECEIVE: "CONTACTS_RECEIVE",
    DATE_DETAILS_MODAL_HIDE: "DATE_DETAILS_MODAL_HIDE",
    DATE_DETAILS_MODAL_SHOW: "DATE_DETAILS_MODAL_SHOW",
    DATE_DETAILS_SHOW: "DATE_DETAILS_SHOW",
    ITINERARY_RECEIVE: "ITINERARY_RECEIVE",
    LOADING_SET: "LOADING_SET",
    LOADING_ITINERARY_SET: "LOADING_ITINERARY_SET",
    SELECTED_DATES_SET: "SELECTED_DATES_SET",
    RESET: "RESET",
    ITINERARY_DATES_SHOW_EARLIER: "ITINERARY_DATES_SHOW_EARLIER",
    ITINERARY_DATES_SHOW_LATER: "ITINERARY_DATES_SHOW_LATER",
    ITINERARY_QUERY_RESET: "ITINERARY_QUERY_RESET",
    ITINERARY_QUERY_CONTACT_SET: "ITINERARY_QUERY_CONTACT_SET",
    ITINERARY_QUERY_ARTIST_SET: "ITINERARY_QUERY_ARTIST_SET",
    ARTIST_ONLYFAVORITE_SELECTION: "ARTIST_ONLYFAVORITE_SELECTION",
    ARTIST_ISARTISTTYPEFILTER_SELECTION: "ARTIST_ISARTISTTYPEFILTER_SELECTION",
    TOGGLE_ARTIST_TAG: "TOGGLE_ARTIST_TAG",
    CLEAR_STALE_SELECTED_TAGS: "CLEAR_STALE_SELECTED_TAGS",
    SET_PAGINATION: "SET_PAGINATION",
    PAGINATION_CHANGE_PAGE: "PAGINATION_CHANGE_PAGE",
    FAVORITE_ARTISTS_SET: "FAVORITE_ARTISTS_SET"
  },
  ErrorHandling: {
    ERROR_DISPLAY: "ERROR_DISPLAY",
    ERROR_CONTAINER: "ERROR_CONTAINER",
    ERROR_HIDE: "ERROR_HIDE"
  },
  ExcelTemplate: {
    EXPORT_TEMPLATE_CHANGED: "EXPORT_TEMPLATE_CHANGED"
  },
  Genre: {
    PUBLICGENRES_RECEIVE: "PUBLICGENRES_RECEIVE",
    CLIENTGENRES_RECEIVE: "CLIENTGENRES_RECEIVE"
  },
  Guestlist: {
    GUESTLIST_SETTINGS_SHOW: "GUESTLIST_SETTINGS_SHOW",
    GUESTLIST_SETTINGS_HIDE: "GUESTLIST_SETTINGS_HIDE",
    GUEST_ADDING_MODAL_SHOW: "GUEST_ADDING_MODAL_SHOW",
    GUEST_ADDING_MODAL_HIDE: "GUEST_ADDING_MODAL_HIDE",
    SET_SHOW_ID: "SET_SHOW_ID",
    SET_CURRENT_EDITED_GUESTLIST_PLACE: "SET_CURRENT_EDITED_GUESTLIST_PLACE",
    SET_SHOW_DELETE_MODAL: "SET_SHOW_DELETE_MODAL",
    SET_SEARCH_STATE: "SET_SEARCH_STATE",
    SET_GUESTLIST_DELETE_MODAL: "SET_GUESTLIST_DELETE_MODAL",
    GUESTLIST_PLACE_ADD_REQUEST: "GUESTLIST_PLACE_ADD_REQUEST",
    GUESTLIST_PLACE_ADD_SUCCESS: "GUESTLIST_PLACE_ADD_SUCCESS",
    GUESTLIST_PLACE_ADD_FAILURE: "GUESTLIST_PLACE_ADD_FAILURE",
    GUEST_ADD_REQUEST: "GUEST_ADD_REQUEST",
    GUEST_ADD_SUCCESS: "GUEST_ADD_SUCCESS",
    GUEST_ADD_FAILURE: "GUEST_ADD_FAILURE",
    GUESTLIST_ADD_REQUEST: "GUESTLIST_ADD_REQUEST",
    GUESTLIST_ADD_SUCCESS: "GUESTLIST_ADD_SUCCESS",
    GUESTLIST_ADD_FAILURE: "GUESTLIST_ADD_FAILURE",
    GUESTLIST_REQUEST: "GUESTLIST_REQUEST",
    GUESTLIST_SUCCESS: "GUESTLIST_SUCCESS",
    GUESTLIST_FAILURE: "GUESTLIST_FAILURE",
    GUESTLIST_PLACE_EDIT_REQUEST: "GUESTLIST_PLACE_EDIT_REQUEST",
    GUESTLIST_PLACE_EDIT_SUCCESS: "GUESTLIST_PLACE_EDIT_SUCCESS",
    GUESTLIST_PLACE_EDIT_FAILURE: "GUESTLIST_PLACE_EDIT_FAILURE",
    GUEST_UPDATE_REQUEST: "GUEST_UPDATE_REQUEST",
    GUEST_UPDATE_SUCCESS: "GUEST_UPDATE_SUCCESS",
    GUEST_UPDATE_FAILURE: "GUEST_UPDATE_FAILURE",
    GUESTLIST_UPDATE_REQUEST: "GUESTLIST_UPDATE_REQUEST",
    GUESTLIST_UPDATE_SUCCESS: "GUESTLIST_UPDATE_SUCCESS",
    GUESTLIST_UPDATE_FAILURE: "GUESTLIST_UPDATE_FAILURE",
    GUESTLIST_DELETE_REQUEST: "GUESTLIST_DELETE_REQUEST",
    GUESTLIST_DELETE_SUCCESS: "GUESTLIST_DELETE_SUCCESS",
    GUESTLIST_DELETE_FAILURE: "GUESTLIST_DELETE_FAILURE",
    GUESTLIST_PLACE_REMOVE_REQUEST: "GUESTLIST_PLACE_REMOVE_REQUEST",
    GUESTLIST_PLACE_REMOVE_SUCCESS: "GUESTLIST_PLACE_REMOVE_SUCCESS",
    GUESTLIST_PLACE_REMOVE_FAILURE: "GUESTLIST_PLACE_REMOVE_FAILURE",
    GUEST_REMOVE_REQUEST: "GUEST_REMOVE_REQUEST",
    GUEST_REMOVE_SUCCESS: "GUEST_REMOVE_SUCCESS",
    GUEST_REMOVE_FAILURE: "GUEST_REMOVE_FAILURE"
  },
  HandleAdvancingRequest: {
    REQUESTS_RECEIVE: "REQUESTS_RECEIVE",
    FORM_DATA_RECEIVE: "FORM_DATA_RECEIVE",
    RESPONSES_REFRESH: "RESPONSES_REFRESH",
    RESPONSE_ADD: "RESPONSE_ADD",
    HANDLE_REQUEST_HIDE: "HANDLE_REQUEST_HIDE",
    REQUEST_SHOW: "REQUEST_SHOW",
    LOADING_SHOW: "LOADING_SHOW"
  },
  ItineraryExport: {
    CHANGE_EXPORT_PEEK: "CHANGE_EXPORT_PEEK",
    UPDATE_IS_BY_ARTIST: "UPDATE_IS_BY_ARTIST",
    UPDATE_ARTISTS: "UPDATE_ARTISTS",
    UPDATE_CONTACTS: "UPDATE_CONTACTS",
    TOGGLE_APPOINTMENT_TYPE: "TOGGLE_APPOINTMENT_TYPE",
    UPDATE_START_DATE: "UPDATE_START_DATE",
    UPDATE_LANGUAGE: "UPDATE_LANGUAGE",
    UPDATE_END_DATE: "UPDATE_END_DATE",
    UPDATE_SETTINGS: "UPDATE_SETTINGS",
    TOGGLE_PHASE: "TOGGLE_PHASE",
    CHANGE_URL: "CHANGE_URL",
    UPDATE_ITINERARY_SELECTION: "UPDATE_ITINERARY_SELECTION"
  },
  LockShows: {
    UPDATE_BEFORE_DATE: "UPDATE_BEFORE_DATE",
    CHANGE_UNLOCKED_SHOW_COUNT: "CHANGE_UNLOCKED_SHOW_COUNT",
    REMOVE_LOCK_HANDLER: "REMOVE_LOCK_HANDLER",
    ADD_LOCK_HANDLER: "ADD_LOCK_HANDLER",
    UPDATE_BOOKKEEPING_SELECTION: "UPDATE_BOOKKEEPING_SELECTION",
    UPDATE_STATUSES_SELECTION: "UPDATE_STATUSES_SELECTION"
  },
  Notifications: {
    NOTIFICATIONBADGE_SET: "NOTIFICATIONBADGE_SET",
    NOTIFICATIONS_RECEIVE: "NOTIFICATIONS_RECEIVE",
    NOTIFICATIONS_ALLREAD: "NOTIFICATIONS_ALLREAD",
    NOTIFICATIONS_READ: "NOTIFICATIONS_READ",
    NOTIFICATIONS_ALLSEEN: "NOTIFICATIONS_ALLSEEN",
    NOTIFICATIONS_UNREAD: "NOTIFICATIONS_UNREAD",
    NOTIFICATIONS_HIDDEN: "NOTIFICATIONS_HIDDEN",
    NOTIFICATIONS_LOADING_COMPLETE: "NOTIFICATIONS_LOADING_COMPLETE",
    NOTIFICATIONS_ALLSEEN_RECEIVE: "NOTIFICATIONS_ALLSEEN_RECEIVE",
    NOTIFICATIONS_REQUEST: "NOTIFICATIONS_REQUEST",
    NOTIFICATIONS_SUCCESS: "NOTIFICATIONS_SUCCESS",
    NOTIFICATIONS_FAILURE: "NOTIFICATIONS_FAILURE"
  },
  ChangeSecretQuestion: {
    CURRENTANSWER_RECEIVE: "CURRENTANSWER_RECEIVE",
    CURRENTQUESTION_RECEIVE: "CURRENTQUESTION_RECEIVE",
    QUESTION_CHANGE: "QUESTION_CHANGE"
  },
  PersonalSettings: {
    USERPREFERENCESAVE_REQUEST: "USERPREFERENCESAVE_REQUEST",
    EMAILSETTINGSSAVE_REQUEST: "EMAILSETTINGSSAVE_REQUEST",
    REGIONALSETTINGSSAVE_REQUEST: "REGIONALSETTINGSSAVE_REQUEST",
    STYLESETTINGS_UPDATE: "STYLESETTINGS_UPDATE",
    STYLESETTINGSSAVE_REQUEST: "STYLESETTINGSSAVE_REQUEST",
    PERSONALSETTINGS_SUCCESS: "PERSONALSETTINGS_SUCCESS"
  }
};